import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

function add_calendarEvent(params) {
  //console.log('add event---');
    const baseUrl = '/add-event';
    return api.create(`${baseUrl}`, params);
    
}
function add_contactsInfo(params) {
  //console.log('add event---');
    const baseUrl = '/add-contact';
    return api.create(`${baseUrl}`, params);
    
}
        
function add_usersTasks(params) {
  //console.log('add task---');
    const baseUrl = '/add-task';
    return api.create(`${baseUrl}`, params);
    
}
function add_priorityApi(params) {
  //console.log('add priority---');
    const baseUrl = '/add-priority';
    return api.create(`${baseUrl}`, params);
    
}
function add_positionApi(params) {
  //console.log('add position---');
    const baseUrl = '/add-position';
    return api.create(`${baseUrl}`, params);
    
}
function add_commentApi(params) {
  //console.log('add comment---');
    const baseUrl = '/add-comment';
    return api.create(`${baseUrl}`, params);
    
}
function add_messageApi(params) {
  //console.log('add message---');
    const baseUrl = '/save-message';
    return api.create(`${baseUrl}`, params);
    
}
        

export {add_calendarEvent,add_contactsInfo,add_usersTasks,add_priorityApi,add_positionApi,add_commentApi,add_messageApi};
