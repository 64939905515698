import React, { Suspense, useEffect, useState } from 'react';
import { Container,Row,Col,Breadcrumb } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

// hooks
import { useRedux } from '../hooks/';
import moment from "moment"
// constants
import { LayoutTypes, SideBarTypes } from '../constants';
import axios from "axios";
// utils
import { changeBodyAttribute } from '../utils';
import MainLoader from '../components/MainLoader';
import {
    changeLayout,
    changeLayoutColor,
    changeLayoutWidth,
    changeMenuPositions,
    changeSidebarTheme,
    changeSidebarType,
    toggleSidebarUserInfo,
    changeTopbarTheme,themeCustomize, changeFooterType,
} from '../redux/actions';

// constants
import * as layoutConstants from '../constants/layout';
// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar/'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const RightSidebar = React.lazy(() => import('./RightSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <div className=""></div>;

const VerticalLayout = () => {
    const { dispatch,appSelector } = useRedux();
    let [themeItems,setThemeItems] = useState(null)
    const {
        layoutColor,
        layoutWidth,
        menuPosition,
        leftSideBarTheme,
        leftSideBarType,
        showSidebarUserInfo,toggleFooter,
        topbarTheme,pageTitle,
    } = appSelector((state) => ({
        layoutColor: state.Layout.layoutColor,
        layoutWidth: state.Layout.layoutWidth,
        menuPosition: state.Layout.menuPosition,
        leftSideBarTheme: state.Layout.leftSideBarTheme,
        leftSideBarType: state.Layout.leftSideBarType,
        showSidebarUserInfo: state.Layout.showSidebarUserInfo,
        topbarTheme: state.Layout.topbarTheme,
        toggleFooter: state.Layout.toggleFooter,
        pageTitle: state.PageTitle.pageTitle,
    }));

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    /*
  layout defaults
  */
    useEffect(() => {
        changeBodyAttribute('data-layout-mode', LayoutTypes.LAYOUT_VERTICAL);
    }, []);

    useEffect(() => {
        changeBodyAttribute('data-layout-color', layoutColor);
    }, [layoutColor]);
    useEffect(() => {
        changeBodyAttribute('scroll-footer', toggleFooter);
    }, [toggleFooter]);

    useEffect(() => {
        changeBodyAttribute('data-layout-size', layoutWidth);
    }, [layoutWidth]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-position', menuPosition);
    }, [menuPosition]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-color', leftSideBarTheme);
    }, [leftSideBarTheme]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-size', leftSideBarType);
    }, [leftSideBarType]);

    useEffect(() => {
        changeBodyAttribute('data-topbar-color', topbarTheme);
    }, [topbarTheme]);

    useEffect(() => {
        changeBodyAttribute('data-sidebar-user', showSidebarUserInfo);
    }, [showSidebarUserInfo]);
   
   useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 1000);
    handleResize(); // Call once to set initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => !prevState);

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };

    const isCondensed: boolean = leftSideBarType === SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED;
    const [companyInfo, setCompanyInfo] = useState(null);
    const [weather, setWeather] = useState(null);
    const [branchName, setBranchName] = useState('');
    const [weatherIcon, setWeatherIcon] = useState(null);
    const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss"));
    const [menusItems, setMenuItems] = useState([]);
    const [loadings, setLoading] = useState(false);

   useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
      getWeather();
      let user = sessionStorage.getItem("comp_Info")
      let covertJson = JSON.parse(user);
      setCompanyInfo(covertJson);
    }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);
    const getWeather = async ()=>{
        await axios.get('/get-weather').then((res)=>{
            //console.log(res.data)
            setWeather(res.data)
            if(res.data.weather[0].main==="Clear"){
                setWeatherIcon("mdi mdi-weather-partly-cloudy")
            }else if(res.data.weather[0].main==="Clouds"){
                setWeatherIcon("mdi mdi-weather-cloudy")
            }
        }).catch((error)=>error)
    }
 const [time, setTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const timerInterval = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  const updateTimer = () => {
    setTime(prevTime => {
      const { hours, minutes, seconds } = prevTime;

      if (seconds < 59) {
        return { ...prevTime, seconds: seconds + 1 };
      } else if (minutes < 59) {
        return { ...prevTime, seconds: 0, minutes: minutes + 1 };
      } else {
        return { seconds: 0, minutes: 0, hours: hours + 1 };
      }
    });
  };
   
     useEffect(()=>{
         setLoading(true)
         getMenusData();
         getThemeData();
         
     },[])
     const getMenusData = async()=>{
        await axios.get('/get-menus').then((res)=>{
            setMenuItems(res.data.menus)
           
        })
     }
      const getThemeData = async()=>{
        await axios.get('/get-theme-customization').then((res)=>{
            setThemeItems(res.data.customization)
             dispatch(changeLayout(res.data.customization.layout));
         dispatch(changeLayoutColor(res.data.customization.colorscheme));
        dispatch(changeLayoutWidth(res.data.customization.width));
         dispatch(changeMenuPositions(res.data.customization.menuposition));
         dispatch(changeSidebarTheme(res.data.customization.sidebarcolor));
        dispatch(changeSidebarType(res.data.customization.sidebarsize));
         dispatch(changeTopbarTheme(res.data.customization.topbarTheme));
         dispatch(changeFooterType(res.data.customization.footerType));
         getBranch();
        })
     }
      const getBranch = async()=>{
       axios.get('/get-branch-information').then((res)=>{
            setBranchName(res.data?.name);
            setLoading(false);
       })
     }
     if(!loadings)
    return (
        <>
            <div id="wrapper">
                <Suspense fallback={loading()}>
                    <Topbar openLeftMenuCallBack={openMenu} branchName={branchName}/>
                    
                </Suspense>
                
                <Suspense fallback={loading()}>
                    <LeftSidebar isCondensed={isCondensed} menusItems={menusItems}/>
                </Suspense>
                <div className="content-page">
                    <div className="content">
                    
                        <Container fluid >
                        {(isSmallScreen) ?
                                    <Row>
                                <Col xs={12}>
                                    <div className="p-1">
                                        <h4 className="page-title">{pageTitle.title!=undefined&&pageTitle.title!=null?pageTitle.title:branchName}</h4>
                                    </div>
                                </Col>
                            </Row>
                                
                            :''}
                            

                            <Outlet />
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                    
                        <Footer />
                    </Suspense>
                    
                </div>
            </div>

            <Suspense fallback={loading()}>
                <RightSidebar themeItems={themeItems} getThemeData={getThemeData}/>
            </Suspense>
        </>
    );
     return (<>
      <MainLoader useInfo={companyInfo}/></>);
};

export default VerticalLayout;
