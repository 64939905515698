import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import {add_calendarEvent,add_contactsInfo,add_usersTasks,add_priorityApi,add_positionApi,add_commentApi,add_messageApi} from '../../helpers';

import { appsResponseSuccess, appsResponseError } from './actions';

// constants
import { AppsActionTypes } from './constants';
type calendarEvent_type = {
    payload: {title: string;className: string;startdate: string;enddate: string;allDay: string;};
    type: string;
};
type contacts_type = {
    payload: {name: string;company: string;position: string;phone: string;email: string;cityid: string;address: string;};
    type: string;
};
type tasks_type = {
    payload: {title: string;assignto: string;assignby: string;priority: string;startdate: string;enddate: string;description: string;};
    type: string;
};
type add_pir_type = {
    payload: {name:string;};
    type: string;
};
type add_pro_type = {
    payload: {name:string;};
    type: string;
};
type add_com_type = {
    payload: {taskid:string;sendto:string;comment:string;};
    type: string;
};
type add_mes_type = {
    payload: {from:string;to:string;message:string;};
    type: string;
};


function* add_calendar({ payload: {title,className,startdate,enddate,allDay}}:calendarEvent_type): SagaIterator{
    try {
        const response = yield call(add_calendarEvent, {title,className,startdate,enddate,allDay});
        //console.log(response);
        const item = response.data;
        yield put(appsResponseSuccess(AppsActionTypes.CALENDAR_EVENT , item));
    } catch (error) {
        yield put(appsResponseSuccess(AppsActionTypes.CALENDAR_EVENT , error));
            }
}
function* add_contacts({ payload: {name,company,position,phone,email,cityid,address}}:contacts_type): SagaIterator{
    try {
        const response = yield call(add_contactsInfo, {name,company,position,phone,email,cityid,address});
        const item = response.data;
        yield put(appsResponseSuccess(AppsActionTypes.ADD_CONTACT , item));
    } catch (error) {
        yield put(appsResponseSuccess(AppsActionTypes.ADD_CONTACT , error));
            }
}
function* usersTasks({ payload: {title,assignto,assignby,priority,startdate,enddate,description}}:tasks_type): SagaIterator{
    try {
        const response = yield call(add_usersTasks, {title,assignto,assignby,priority,startdate,enddate,description});
        const item = response.data;
        yield put(appsResponseSuccess(AppsActionTypes.ADD_TASKS , item));
    } catch (error) {
        yield put(appsResponseSuccess(AppsActionTypes.ADD_TASKS , error));
            }
}
function* add_priority({ payload: {name}}:add_pir_type): SagaIterator{
    try {
        const response = yield call(add_priorityApi, {name});
        const item = response.data;
        yield put(appsResponseSuccess(AppsActionTypes.ADD_PRIORITY , item));
    } catch (error) {
        yield put(appsResponseSuccess(AppsActionTypes.ADD_PRIORITY , error));
            }
}
function* add_position({ payload: {name}}:add_pro_type): SagaIterator{
    try {
        const response = yield call(add_positionApi, {name});
        const item = response.data;
        yield put(appsResponseSuccess(AppsActionTypes.ADD_POSITION , item));
    } catch (error) {
        yield put(appsResponseSuccess(AppsActionTypes.ADD_POSITION , error));
            }
}
function* add_Comment({ payload: {taskid,sendto,comment}}:add_com_type): SagaIterator{
    try {
        const response = yield call(add_commentApi, {taskid,sendto,comment});
        const item = response.data;
        yield put(appsResponseSuccess(AppsActionTypes.ADD_COMMENT , item));
    } catch (error) {
        yield put(appsResponseSuccess(AppsActionTypes.ADD_COMMENT , error));
            }
}
function* add_Message({ payload: {from,to,message}}:add_mes_type): SagaIterator{
    try {
        const response = yield call(add_messageApi, {from,to,message});
        const item = response.data;
        yield put(appsResponseSuccess(AppsActionTypes.ADD_MESSAGE , item));
    } catch (error) {
        yield put(appsResponseSuccess(AppsActionTypes.ADD_MESSAGE , error));
            }
}




export function* watchAdd(): any{   
   yield takeEvery(AppsActionTypes.CALENDAR_EVENT, add_calendar);
   yield takeEvery(AppsActionTypes.ADD_PRIORITY, add_priority);
   yield takeEvery(AppsActionTypes.ADD_POSITION, add_position);
   yield takeEvery(AppsActionTypes.ADD_CONTACT, add_contacts);
   yield takeEvery(AppsActionTypes.ADD_COMMENT, add_Comment);
   yield takeEvery(AppsActionTypes.ADD_TASKS, usersTasks);
   yield takeEvery(AppsActionTypes.ADD_MESSAGE, add_Message);
}


function* appSaga() {
    yield all([fork(watchAdd)]);
}

export default appSaga;
