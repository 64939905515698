import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

function add_GroupAccounts(params) {
  //console.log('add account---');
    const baseUrl = '/add-groupaccount';
    return api.create(`${baseUrl}`, params);
    
}
function upd_GroupAccounts(params) {
  //console.log('update account---');
    const baseUrl = `/update-groupaccount/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function addDetailAcc_API(params) {
  //console.log('update account---');
    const baseUrl = '/add-detailaccount'
    return api.create(`${baseUrl}`, params);
    
}
function updDetailAcc_API(params) {
  //console.log('update account---');
    const baseUrl = `/update-detailaccount/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function openAccBalances(params) {
    const baseUrl = `/add-account-balance`;
    return api.create(`${baseUrl}`, params);
    
}

function addCash_Payments(params) {
  //console.log('add Cash Payments---');
    const baseUrl = '/add-cash-payment';
    return api.create(`${baseUrl}`, params);
    
}
        
function updCash_Payments(params) {
  //console.log('update Cash Payment---');
    const baseUrl = `/update-cash-payment/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function addExpence_Voucher(params) {
  //console.log('add Expence_Voucher---');
    const baseUrl = '/add-expense-voucher';
    return api.create(`${baseUrl}`, params);
    
}
        
function updExpence_Voucher(params) {
  //console.log('update upd Expence_Voucher---');
    const baseUrl = `/update-expense-voucher/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function addBank_Payments(params) {
  //console.log('add bank Payments---');
    const baseUrl = '/add-bank-payment';
    return api.create(`${baseUrl}`, params);
    
}
        
function updBank_Payments(params) {
  //console.log('update bank Payment---');
    const baseUrl = `/update-bank-payment/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}

function addCash_Receipts(params) {
  //console.log('add Cash Receipts---');
    const baseUrl = '/add-cash-receipt';
    return api.create(`${baseUrl}`, params);
    
}
function updCash_Receipts(params) {
  //console.log('update Cash Receipts---');
    const baseUrl = '/update-cash-receipt/'+params.id;
    return api.update(`${baseUrl}`, params);
    
}
function addBank_Receipts(params) {
  //console.log('add bank Receipts---');
    const baseUrl = '/add-bank-receipt';
    return api.create(`${baseUrl}`, params);
    
}
function updBank_Receipts(params) {
  //console.log('update bank Receipts---');
    const baseUrl = '/update-bank-receipt/'+params.id;
    return api.update(`${baseUrl}`, params);
    
}
function JV_Api(params) {
  //console.log('add JV---');
    const baseUrl = '/add-journal-voucher';
    return api.create(`${baseUrl}`, params);
    
}
function UPD_JV_Api(params) {
  //console.log('update JV ID wise---');
    const baseUrl = '/update-journal-voucher/'+params.id;
    return api.update(`${baseUrl}`, params);
    
}

export {addExpence_Voucher,
updExpence_Voucher,addBank_Receipts,
updBank_Receipts,addBank_Payments,updBank_Payments,JV_Api,
UPD_JV_Api,updCash_Receipts,addCash_Receipts,addCash_Payments,updCash_Payments,openAccBalances,upd_GroupAccounts,add_GroupAccounts,addDetailAcc_API,updDetailAcc_API};
